.section h2{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 40px;
}


.index_topics_size{
    width: 100%;
    padding-bottom: 50%;
  }
  
.topics{
    position: relative;
    height: 0;
    box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid white;
}


.no_padding {
    padding: 0 !important;
}


.transition:hover{
    transform: scale(1.1);
    transition: all 1s;
}
  
.fill_div{
    width: 100%;
}


.centered_down {
    font-size: 20px;
    margin: auto;
}


.category_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
    color: #393939;
}

.joven{
    display: flex;
    justify-items: center;
    text-align: left;
}