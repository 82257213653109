.obj{
    background-color: #FF968F;
}


.obj p, h1{
    font-family: 'Poppins';
    font-style: normal;
    color: #FFFFFF;
    width: 60%;
    text-align: justify;
    margin-left: 100px;

}

.font_1_ {
    font-family: 'Poppins';
    font-style: normal;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 700;
    padding-top: 80px;
    padding-bottom: 100px;
}

.font_2_ {
    font-size: 20px;
    font-weight: 400;
}

.inline {
    padding-top: 50px;
    display: flex;
    margin: auto;
    width: 30%;
    justify-content: center;
    margin-bottom: 50px;
}

.inline button{
    font-weight: 500;
    color: white;
    font-size: 22px;
    background-color: transparent;
    border: none;
}


.button_1 {
    padding-right: 5px
}

.button_2 {
    width: 1px;
}

.button_3 {
    padding-left: 5px;
}

.x{
    width: 400px;
}

li {
    margin-bottom: 50px;
    list-style-type: none;
}

.list_style {
    border-radius: 20px;
    margin: auto;
    width: 40%;
    height: 500px;
    overflow: scroll;
    font-size: 20px;
    background-color: white;
    padding-top: 30px;
    font-weight: bold;
}

.list_style li{
    color: #FF968F;
}

.centered { 
    padding-top: 55px;
    width: 50%;
    margin: auto;
}
.centered p{ 
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.centered button{ 
    border-radius: 10px;
    border-style: none;
    background-color: #86BDBC;
    padding: 5px 20px 5px 20px;
    color: white
}


