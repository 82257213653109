.bk_color {
    background-color: #2D9E8A;
    overflow: scroll;
}

.wow {
    padding-top: 50px;
    padding-bottom: 100px;
    min-height:200px;
    overflow: scroll;
}


.ianbo{
    height: 250px;
    background-color: #2D9E8A;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ianbo p{
    font-family: 'Poppins';
    font-style: normal;
    margin-left: 50px;
}

.font_1 {
    font-size: 35px;
    font-weight: 700;
}

.font_2 {
    font-size: 20px;
    font-weight: 400;
}

.hjo{
    margin: auto;
    width: 90%;
}

.texto {
    line-height: 2;
    text-align: justify;
  }

h2 {
    text-align: left;
    margin-left: 40px;
}