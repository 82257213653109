.cochin {
    width: 70%;
    margin:auto;
}

.samm {
    justify-content: center;
    margin-bottom: 50px;
}

.bck_color {
    background-color: #FF968F;
}