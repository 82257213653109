.main_div{
    border-radius: 50px;
    background-color: white;
    color: black;
}

.container_box{
    position: relative;
    margin-bottom: 1000px;
}

.box{
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}

.box_2{
    width: 100%;
    height: 300px;
}

.stack-top {
    z-index: 0;
    margin-top: 300px;
}

.comenzar {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 22px;
    color: #656565;
    padding-top: 50px;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 1px;

}


.spacing_footer{
    height: 120px;
}