.section2 h2{
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 35px;
    text-align: left;
    margin-left: 100px;
  
}

.index_topics_size{
    width: 100%;
    padding-bottom: 50%;
  }
  
.topics{
    position: relative;
    height: 0;
}

.no_padding {
    padding: 0 !important;
}


.transition:hover{
    transform: scale(1.1);
    transition: all 1s;
}
  
.fill_div2{
    width: 250px;
    border-radius: 20px;
}

.centered_down {
    font-size: 20px;
    text-align: center;
}


.category_title2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
    color: #393939;
    text-transform: uppercase;
}


.card {
    box-shadow: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 4px;
  }
  
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 10 0 1px rgba(255, 255, 255, .5);
}