.barra {
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: #72D3AD;
  height: 50px;
  
}

.barra img {
  margin-left: 20px;
  height: 100%;
}


.header {
  position: sticky;
  z-index: 2;
  top: 0;
}

  



  